import * as React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

// Components
import Footer from "src/components/footer"
import FooterCta from "src/components/footerCta"
import Header from "src/components/header"
import Layout from "src/components/layout"
import SEO from "src/components/seo"

// Styles
import "src/styles/templates/legal.scss"

// SEO
const title = "Terms, conditions & data security – Timetastic"
const description = "Contractual questions including security and GDPR"

const LegalArticle = ({ data }) => {
  React.useEffect(() => {
    if (data.mdx.frontmatter.title === "Cookie policy" && window.OneTrust) {
      window.OneTrust.initializeCookiePolicyHtml();
    }
  }, [{data}])

  return (
    <>
      <SEO
        title={data.mdx.frontmatter.title}
        useTitleTemplate={false}
        description={data.mdx.frontmatter.description}
      />
      <Layout pageTitle={data.mdx.frontmatter.title}>
        <Header />
        <FooterCta />
        <main className="c-legal-main">
          <section className="c-section c-legal__container">
            <div className="u-inner u-inner--800">
              <div className="c-legal__content">
                <h1 className="h h1">{data.mdx.frontmatter.title}</h1>
                <div className="c-legal__content-date">
                  {data.mdx.frontmatter.dateprefix}
                  {data.mdx.frontmatter.date}
                </div>
                <MDXRenderer>{data.mdx.body}</MDXRenderer>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </Layout>
    </>
  )
}
export const query = graphql`
  query($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        description
        dateprefix
        date(formatString: "MMMM D, YYYY")
      }
      body
    }
    allMdx(sort: { fields: frontmatter___order, order: ASC }) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
        }
        id
        slug
      }
    }
  }
`

export default LegalArticle
